<template>
  <div>
    <b-overlay :show="false" rounded="sm">
    <b-card>
      <b-form class="mt-2" ref="formHTML">       
        <b-row>
          <!-- <b-col sm="6">
            <b-form-group label="Descripción" label-for="description">
              <b-form-input  placeholder="Descripción" name="description" />
            </b-form-group>
          </b-col> -->
          <b-col sm="6">
            <b-form-group label="Comentarios" label-for="comment">
              <b-form-input  name="comment" placeholder="Comentarios" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" class="mt-2 mr-1" type="button" @click="saveForm">
              Guardar cambios
            </b-button>
            <b-button variant="warning" class="mt-2 mr-1" type="button" link to="/">
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
  import { BButton, BForm, BFormRadio, BOverlay,BCardHeader, BCardBody, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BLink, BImg } from 'bootstrap-vue'  
import { data } from 'vue-echarts';
  export default {
    components : { BButton, BForm, BFormRadio, BOverlay,BCardHeader, BCardBody, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BLink, BImg},
    data() {
      return {
        order : {description : null, comment : null }
      }
    },
    methods: {
      saveForm : function() {

      }
    }
  }
</script>

<style>

</style>